const env = require('./src/config');

module.exports = {
  translation: {
    // default / fallback language
    defaultLanguage: env.defaultLanguage,
    localesPath: './locales/',

    // needed for serverside preload
    allLanguages: ['de', 'en', 'fr', 'it'],

    // optional settings needed for subpath (/de/page1) handling
    enableSubpaths: false,
    subpathsOnNonDefaultLanguageOnly: false, // only redirect to /lng/ if not default language
  },
};
