const development = require('./development');
const production = require('./production');
const local = require('./local');
const Logger = require('../utils/logger');

const getConfig = require('next/config').default;

const publicRuntimeConfig = getConfig() ? getConfig().publicRuntimeConfig : null;
const env = publicRuntimeConfig ? publicRuntimeConfig.REACT_APP_BUILD_ENV : process.env.REACT_APP_BUILD_ENV;

const bookingTermsUrl = publicRuntimeConfig ? publicRuntimeConfig.BOOKING_TERMS_URL : process.env.BOOKING_TERMS_URL;
const privacyUrl = publicRuntimeConfig ? publicRuntimeConfig.PRIVACY_URL : process.env.PRIVACY_URL;
const brandName = publicRuntimeConfig ? publicRuntimeConfig.BRAND_NAME : process.env.BRAND_NAME;
const faqUrl = publicRuntimeConfig ? publicRuntimeConfig.FAQ_URL : process.env.FAQ_URL;

const isDirectoryEnabled = publicRuntimeConfig ? publicRuntimeConfig.IS_DIRECTORY_ENABLED : process.env.IS_DIRECTORY_ENABLED;

const logoWhiteUrl = publicRuntimeConfig ? publicRuntimeConfig.LOGO_WHITE_URL : process.env.LOGO_WHITE_URL;
const logoBlueUrl = publicRuntimeConfig ? publicRuntimeConfig.LOGO_BLUE_URL : process.env.LOGO_BLUE_URL;
const favIconUrl = publicRuntimeConfig ? publicRuntimeConfig.FAVICON_URL : process.env.FAVICON_URL;

const defaultLanguageFull = publicRuntimeConfig ? publicRuntimeConfig.DEFAULT_LANGUAGE_FULL : process.env.DEFAULT_LANGUAGE_FULL;
const defaultLanguage = publicRuntimeConfig ? publicRuntimeConfig.DEFAULT_LANGUAGE : process.env.DEFAULT_LANGUAGE;

const widgetDemoCentre = publicRuntimeConfig ? publicRuntimeConfig.WIDGET_DEMO_CENTRE : process.env.WIDGET_DEMO_CENTRE;
const widgetDemoProvider = publicRuntimeConfig ? publicRuntimeConfig.WIDGET_DEMO_PROVIDER : process.env.WIDGET_DEMO_PROVIDER;
const widgetDemoProviderShow = publicRuntimeConfig ? publicRuntimeConfig.WIDGET_DEMO_PROVIDER_SHOW === 'true' ? true : false : process.env.WIDGET_DEMO_PROVIDER_SHOW === 'true' ? true : false;
const widgetDemoProviderShowHide = publicRuntimeConfig ? publicRuntimeConfig.WIDGET_DEMO_PROVIDER_SHOW_HIDE === 'true' ? true : false : process.env.WIDGET_DEMO_PROVIDER_SHOW_HIDE === 'true' ? true : false;
const widgetDemoService = publicRuntimeConfig ? publicRuntimeConfig.WIDGET_DEMO_SERVICE : process.env.WIDGET_DEMO_SERVICE;
const widgetDemoServiceShow = publicRuntimeConfig ? publicRuntimeConfig.WIDGET_DEMO_SERVICE_SHOW === 'true' ? true : false : process.env.WIDGET_DEMO_SERVICE_SHOW === 'true' ? true : false;
const widgetDemoServiceShowHide = publicRuntimeConfig ? publicRuntimeConfig.WIDGET_DEMO_SERVICE_SHOW_HIDE === 'true' ? true : false : process.env.WIDGET_DEMO_SERVICE_SHOW_HIDE === 'true' ? true : false;

module.exports.env = env;

module.exports.bookingTermsUrl = bookingTermsUrl;
module.exports.privacyUrl = privacyUrl;
module.exports.brandName = brandName;
module.exports.faqUrl = faqUrl;

module.exports.isDirectoryEnabled = isDirectoryEnabled;

module.exports.logoWhiteUrl = logoWhiteUrl;
module.exports.logoBlueUrl = logoBlueUrl;
module.exports.favIconUrl = favIconUrl;

module.exports.defaultLanguageFull = defaultLanguageFull;
module.exports.defaultLanguage = defaultLanguage;

module.exports.widgetDemoCentre = widgetDemoCentre;
module.exports.widgetDemoProvider = widgetDemoProvider;
module.exports.widgetDemoProviderShow = widgetDemoProviderShow;
module.exports.widgetDemoProviderShowHide = widgetDemoProviderShowHide;
module.exports.widgetDemoService = widgetDemoService;
module.exports.widgetDemoServiceShow = widgetDemoServiceShow;
module.exports.widgetDemoServiceShowHide = widgetDemoServiceShowHide;

module.exports.isProduction = (env === 'production');

const config = { development, production, local };
Logger.info('Loaded in:', env);

module.exports.default = config[env];
