/* eslint-disable no-console */

class Logger {
  static timeStamp() {
    const time = new Date();
    return `[${time.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1')}]`;
  }


  static log(...args) {
    const { isProduction } = require('../config');
    if ( !isProduction ) {
      console.log(Logger.timeStamp(), ...args);
    }
  }

  static info(...args) {
    const { isProduction } = require('../config');
    if ( !isProduction ) {
      console.info(Logger.timeStamp(), ...args);
    }
  }

  static warn(...args) {
    const { isProduction } = require('../config');
    if ( !isProduction ) {
      console.warn(Logger.timeStamp(), ...args);
    }
  }


  static error(...args) {
    if ( !isProduction ) {
      console.error(Logger.timeStamp(), ...args);
    }
  }
}

module.exports = Logger;
