import api from '../../utils/api';
import { commonClient, publicCommonClient } from '../../api/clients';

const post = (invoice) => {
    return api.post(`/api/invoices`, invoice);
}

const getList = (contactId = '') => {
    return api.get(`/api/invoices${contactId ? '?contactId=' + contactId: ''}`);
}

const update = (id, invoice) => {
    return api.put(`/api/invoices/${id}`, invoice);
}

const remove = (id) => {
    return api.remove(`/api/invoices/${id}`);
}

const send = (ids) => {
    return api.post(`/api/sent-invoices`, ids);
}

const getPdfLinks = (ids) => {
    return api.get(`/api/invoice-pdf-links?${ids.map(id => 'invoiceIds=' + id).join('&')}`, ids);
}

const invoicesWithHash = async (hash, accessToken) => {
  const { data: invoice } = await publicCommonClient({
    method: 'GET',
    url: `/invoices?hash=${hash}&pdfLinks=true`,
    headers: {
      Authorization: 'Bearer ' + accessToken,
    }
  });

  return invoice;
};

const invoicesList = async (userId) => {
  const { data: list } = await commonClient.get(`/invoices/consumer/${userId}`);

  return list;
};

const get = async (id) => {
  const { data: invoice } = await commonClient.get(`/invoices/${id}`);

  return invoice;
}

const markViewed = async (invoiceId) => {
  const { data: invoice } = await commonClient.post(`/viewed-invoices`, {invoiceId});

  return invoice;
}

const getInvoicesAsZIP = async (invoiceIds) => {
  const { data: data } = await commonClient.post(`/invoices/getInvoicesAsZIP`, invoiceIds);

  let href = `data:application/zip;base64,${data.base64}`;
  let fileName = data.fileName;
  let a = document.createElement("a");

  a.href = href;
  a.download = fileName;
  a.click();
}

export default {
    post,
    get,
    getList,
    update,
    remove,
    send,
    getPdfLinks,
    invoicesWithHash,
    invoicesList,
    markViewed,
    getInvoicesAsZIP
}
