import axios from 'axios';
const env = require('../../config').default;
import { requestInterceptor, responseInterceptor } from './clientInterceptors';

export const publicCommonClient = axios.create({ baseURL: env.api.commonUrl });
export const publicDirectoryClient = axios.create({ baseURL: env.api.directoryUrl });
export const publicBookingClient = axios.create({ baseURL: env.api.bookingUrl });

export const commonClient = axios.create({ baseURL: env.api.commonUrl });
export const directoryClient = axios.create({ baseURL: env.api.directoryUrl });
export const bookingClient = axios.create({ baseURL: env.api.bookingUrl });

commonClient.interceptors.request.use(requestInterceptor);
commonClient.interceptors.response.use(undefined, responseInterceptor(commonClient));
bookingClient.interceptors.request.use(requestInterceptor);
bookingClient.interceptors.response.use(undefined, responseInterceptor(bookingClient));

export default {
  publicCommonClient,
  publicDirectoryClient,
  publicBookingClient,
  commonClient,
  directoryClient,
  bookingClient
};
